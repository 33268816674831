import React, {useEffect, useState} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import Card_MC from '@material-ui/core/Card';
import {Typography} from "@material-ui/core";
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import {Card} from 'semantic-ui-react';
import useWindowSize from "../Components/use-window-size";
import Jumbotron from "react-bootstrap/Jumbotron";
import Container from "react-bootstrap/Container";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: "98%",
        marginLeft: "1%",
        display: "flex",
        justifyContent: "space-around",
        flexWrap : "wrap"
    },
    CardRoot: {
        flexGrow: 1,
        width: "100%",
        display: "flex",
        justifyContent: "space-evenly"
        // background: 'linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d)'
    },
    GridContainer: {
        marginTop: "1em",
    },
    ContentDiv: {
        textAlign: "left",
        marginLeft: "2em",
        marginRight: "2em",
        marginTop: "2em",
        fontSize: "20px"
    },
    DownloadText: {
        fontSize: "20px",
        fontWeight: "bold",
        textAlign: "center",
        margin: 0
    },
    PDF: {
        color: "red"
    },
    TitleDiv: {
        textAlign: "center"
    },
    cover: {
        objectFit: "contain",
    },
    WorkDiv: {
        display: "flex",
        justifyContent: "space-between",
        flexWrap : "wrap"
    },
    WorkTitle: {
        display: "flex"
    },
    AchievementCard: {
        width: "98%",
        marginLeft: "1%",
    },
    AchievementPadding: {
        margin: "2em"
    },
    FlexGrow:{
        flexGrow : 1
    }
}));

export default function Education() {
    const classes = useStyles();
    const theme = useTheme();

    const size = useWindowSize();

    const [gridCenterWidth, setGridCenterWidth] = useState(8);
    const [cardNumber, setCardNumber] = useState(4);
    const setGridWidth = (width) => {
        if(width > 1400){
            setGridCenterWidth(8)
            setCardNumber(4)
        }else if(width <= 1400 && width >= 1024){
            setGridCenterWidth(12)
            setCardNumber(4)
        }else if(width < 1024 && width >= 500){
            setGridCenterWidth(12)
            setCardNumber(2)
        }else if(width < 500){
            setGridCenterWidth(12)
            setCardNumber(1)
        }
    };
    useEffect(() => {
        setGridWidth(size.width);
    }, [size])

    const items = [
        {header: 'Received First Class Degree with Valedictorian Award for the class of 2017', color:'blue'},
        {header: 'Received the Dean Scholarship for Academic Excellence', color:'blue'},
        {header: 'Ranked 2nd in IEEE Xtreme 24 Hour Programming Competition (2016)', color:'blue'},
        {header: 'Recipient of Best Final Year Project and Roadshow Demo Award (2016)', color:'blue'},
    ]

    return (
        <section className={"HomeDivContent"} style={{backgroundSize: "100% 60vh"}}>
        <div className={clsx(classes.root, "TopDiv")}>
            <Grid container alignItems={'center'} alignContent={'center'} justify={'center'} spacing={3} className={classes.GridContainer}>
                <Grid item xs={12}>
                    <Jumbotron fluid id={"PageTitle"}>
                        <Container>
                            <Typography variant={"h1"} className={"title-text"}>
                                Education
                            </Typography>
                        </Container>
                    </Jumbotron>
                </Grid>
                <Grid item xs={12}>
                </Grid>
                <Grid item xs={gridCenterWidth} className={classes.FlexGrow}>
                    <Card_MC className={classes.root}>
                        <CardMedia
                            component="img"
                            alt="Contemplative Reptile"
                            height="300"
                            className={classes.cover}
                            image="./res/Image/UoN_Primary.png"
                        />
                        <div className={classes.ContentDiv}>
                            <div className={classes.WorkDiv}>
                                <div className={classes.WorkTitle}>
                                    <Typography variant={"h4"} className={classes.WorkTitle}>
                                        University of Nottingham
                                    </Typography>
                                </div>
                                <div className={classes.WorkTitle}>
                                    <LocationOnIcon style={{fontSize: 40, paddingBottom: "8px"}}/>
                                    <Typography variant={"h5"} className={classes.WorkTitle}>
                                        Kuala Lumpur
                                    </Typography>
                                </div>
                                <div className={classes.WorkTitle}>
                                    <CalendarTodayIcon style={{fontSize: 40, paddingBottom: "8px"}}/>
                                    <Typography variant={"h5"} className={classes.WorkTitle}>
                                        2012 - 2017
                                    </Typography>
                                </div>
                            </div>
                            <div>
                                <Typography variant={"h5"} className={classes.WorkTitle}>
                                    MEng (Hons) Electronics & Computer Engineering
                                </Typography>
                                <p>
                                    The course course covered a detailed insight into computing systems and software.
                                    Covering topics such as analogue and digital electronics, circuits and systems, computing, communications and the application of electrical energy.
                                </p>
                            </div>
                        </div>
                    </Card_MC>
                </Grid>
                <Grid item xs={gridCenterWidth}>
                    <Card_MC className={classes.AchievementCard}>
                        <div className={classes.AchievementPadding}>
                            <Card.Group className={"work-header"} itemsPerRow={cardNumber} centered items={items}/>
                        </div>
                    </Card_MC>
                </Grid>
                <Grid item xs={12}>
                    <div className={"BtmSpc"}>
                    </div>
                </Grid>
            </Grid>
        </div>
        </section>
    );
}