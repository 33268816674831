import React, { Component } from "react";
import { Router, Switch, Route } from "react-router-dom";
import ML from "./ML";
import history from './history';
import Home from '../Home/Home_New';
import Resume from '../Resume/Resume';
import Work from '../Work/Work'
import Skill from '../Skill/Skill';
import Education from '../Education/Education'
import Award from '../Award/Award'
import Blog from '../Blog/Blog'
import Project from '../Projects/Project'
import IntroToML from "../Blog/BlogPages/IntroToML";
import UmpirePage from "../Blog/BlogPages/UmpirePage";
import Type_ML from "../Blog/BlogPages/Type_ML";

export default function Routes() {

    return (
        <Router history={history}>
            <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/Resume" exact component={Resume} />
                <Route path="/ML" component={ML} />
                <Route path="/Work" component={Work} />
                <Route path="/Skill" component={Skill} />
                <Route path="/Education" component={Education} />
                <Route path="/Award" component={Award} />
                <Route path="/Blog" component={Blog} />
                <Route path="/Project" component={Project} />
                <Route path="/IntroToML" component={IntroToML} />
                <Route path="/UmpirePage" component={UmpirePage} />
                <Route path="/Type_ML" component={Type_ML} />
            </Switch>
        </Router>
    );
}

