import React, {useEffect, useState} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import IconButton from '@material-ui/core/IconButton';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import PictureAsPdfSharpIcon from '@material-ui/icons/Description';
import ComplexCard from "./ComplexCard";
import WorkIcon from '@material-ui/icons/WorkOutlineSharp';
import Education from '@material-ui/icons/MenuBookSharp';
import SkillsIcon from '@material-ui/icons/SettingsSharp';
import AwardIcon from '@material-ui/icons/BookSharp';
import ProjectIcon from '@material-ui/icons/LaptopChromebookSharp';
import {Typography} from "@material-ui/core";
import Container from "react-bootstrap/Container";
import Jumbotron from "react-bootstrap/Jumbotron";
import useWindowSize from "../Components/use-window-size";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { PDFObject } from 'react-pdfobject'

const useStyles = makeStyles((theme) => ({
    root: {
        width: "98%",
        marginLeft: "1%",
        display: "flex"
    },
    CardRoot: {
        width: "98%",
        marginLeft: "1%",
        display: "flex",
        justifyContent: "space-evenly",
        flexWrap: "wrap"
        // background: 'linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d)'
    },
    TitleRoot: {
        flexGrow: 1,
        width: "100%",
        display: "flex",
        justifyContent: "space-evenly",
        // background: 'linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d)'
    },
    GridContainer: {
        marginTop: "1em",
    },
    ContentDiv: {
        textAlign: "left",
        marginLeft: "2em",
        marginRight: "2em",
        marginTop: "2em",
        fontSize: "20px"
    },
    DownloadText: {
        fontSize: "20px",
        fontWeight: "bold",
        textAlign: "center",
        margin: 0
    },
    PDF: {
        color: "white",
        background: 'linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d)'
    }
}));

export default function Resume() {
    const classes = useStyles();
    const theme = useTheme();
    const [modalShow, setModalShow] = React.useState(false);
    const size = useWindowSize();
    const [gridCenterWidth, setGridCenterWidth] = useState(2);

    const setGridWidth = (width) => {
        if(width > 1400){
            setGridCenterWidth(2)
        }else if(width <= 1400 && width >= 1024){
            setGridCenterWidth(2)
        }else if(width < 1024 && width >= 500){
            setGridCenterWidth(12)
        }else if(width < 500){
            setGridCenterWidth(12)
        }
        //console.log(width)
    };
    useEffect(() => {
        setGridWidth(size.width);
    }, [size])


    return (
        <section className={"HomeDiv"} style={{backgroundSize: "100% 60vh"}}>
            <div className={clsx(classes.root, "TopDiv")}>
                <Grid container alignItems={'center'} alignContent={'center'} justify={'center'} spacing={3} className={classes.GridContainer}>
                    <Grid item xs={12}>
                        <Jumbotron fluid id={"PageTitle"}>
                            <Container>
                                <Typography variant={"h1"} className={"title-text"}>
                                    About Me
                                </Typography>
                            </Container>
                        </Jumbotron>
                    </Grid>
                    <Grid item xs={12}>
                        <Card className={clsx(classes.root, "CardDesktop")}>
                            {/*<div className="LI-profile-badge" data-version="v1" data-size="medium" data-locale="en_US"*/}
                            {/*     data-type="vertical" data-theme="light" data-vanity="mehulgupta7machinelearning"><a*/}
                            {/*    className="LI-simple-link"*/}
                            {/*    href='https://my.linkedin.com/in/mehulgupta7machinelearning?trk=profile-badge'>Mehul*/}
                            {/*    Gupta</a>*/}
                            {/*</div>*/}
                            <div className={classes.ContentDiv}>
                                <p>
                                    Digital Transformation Lead with close to 3 years’ experience in this field
                                    spearheading
                                    projects to optimise revenue growth, profitability and market share in the
                                    international
                                    Oil & Gas, Semicon, Manufacturing, Academic and Telecommunication sectors.
                                </p>
                                <p>Combines technical expertise with strong academic background to develop robust
                                    machine
                                    learning models, develop strategies & processes and inspire high-performing teams
                                    spread
                                    across South East Asia to achieve organisational goals and targets. </p>
                                <p>
                                    Drives performance and service, utilising highly developed communication, technical
                                    knowledge and
                                    negotiation skills to influence and engage with C-Suite Executives, client
                                    representatives and key stakeholders at all levels.
                                </p>
                                <p className={classes.DownloadText}>
                                    Download the latest resume here
                                    <ArrowRightIcon style={{fontSize: 50}}/>
                                    <IconButton className={classes.PDF} onClick={() => setModalShow(true)}>
                                        <PictureAsPdfSharpIcon style={{fontSize: 40}}/>
                                    </IconButton>
                                </p>
                            </div>
                        </Card>
                    </Grid>

                    <Grid item xs={12}>
                        <Card className={classes.CardRoot}>
                            <ComplexCard CardType={"Work Experience"} CardIcon={<WorkIcon/>} CardLink={"/Work"}/>
                            <ComplexCard CardType={"Education"} CardIcon={<Education/>} CardLink={"/Education"}/>
                            <ComplexCard CardType={"Skills"} CardIcon={<SkillsIcon/>} CardLink={"/Skill"}/>
                            <ComplexCard CardType={"Awards"} CardIcon={<AwardIcon/>} CardLink={"/Award"}/>
                            <ComplexCard CardType={"Projects"} CardIcon={<ProjectIcon/>} CardLink={"/Project"}/>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={"BtmSpc"}>
                        </div>
                    </Grid>
                </Grid>
                <ResumeModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                />
            </div>
        </section>
    );
}

function ResumeModal(props) {
    return (
        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Resume
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div id={"ResumeDiv"}>
                    <PDFObject url="./res/Resume/Mehul Gupta - IOT And AI Solution Lead.pdf" height="75vh"/>
                </div>
            </Modal.Body>
            {/*<Modal.Footer>*/}
            {/*    <Button onClick={props.onHide}>Close</Button>*/}
            {/*</Modal.Footer>*/}
        </Modal>

    );
}