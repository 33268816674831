import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import HomeIcon from '@material-ui/icons/Home';
import BookIcon from '@material-ui/icons/Book';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import history from "./history";
import clsx from "clsx";
import MenuIcon from '@material-ui/icons/Menu';
import useWindowSize from "./use-window-size";

const useStyles = makeStyles({
    root: {
        position: 'fixed',
        bottom: 0,
        backgroundColor: "#00000",
        zIndex: 9999
    },
    BNA: {
        '&.Mui-selected': {
            color: 'white',
            background: 'linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d)',
        }
    },
    navBarLarge: {
        width: "40%",
        left:"30%"
    },
    navbarSmall: {
        width: "100%",
        left:"0%",
    },
});

export default function BottomNavigationMenu() {
    const classes = useStyles();
    const [value, setValue] = React.useState("/");
    const [MenuItem, setMenuItem] = React.useState([]);

    history.listen((location, action) => {
        // location is an object like window.location
        // console.log(action, location.pathname, location.state)
        window.scrollTo(0,0)
        setValue(location.pathname);
        if(location.pathname == "/" || location.pathname == "/Blog" || location.pathname == "/Resume"){
            resetMenuItem();
        }
        else{
            var locationValue = location.pathname.replace("/","");
            console.log(locationValue)
            setMenuItem(<BottomNavigationAction className={classes.BNA}  value={location.pathname} label={locationValue} icon={<MenuIcon/>}/>)
        }
    });

    const handleChange = (event, newValue) => {
        setValue(newValue);
        // alert(newValue)
        history.push(newValue);
    };

    const resetMenuItem = () => {
        setMenuItem([]);
    };

    const [mode, setMode] = React.useState('Large');

    const setMenuWidth = (width) => {
        if(width < 750){
            setMode("Small")
        }else{
            setMode("Large")
        }
    };

    const size = useWindowSize();
    useEffect(() => {
        setMenuWidth(size.width);
    }, [size])

    return (
        <BottomNavigation
            value={value}
            onChange={handleChange}
            showLabels
            className={clsx(classes.root,"MuiPaper-elevation1",{[classes.navbarSmall]: mode === "Small"},{[classes.navBarLarge]: mode === "Large"})}
        >
            <BottomNavigationAction className={classes.BNA} value="/" label="Home" icon={<HomeIcon/>}/>
            <BottomNavigationAction className={classes.BNA}  value="/Blog" label="Blog" icon={<MenuBookIcon/>}/>
            <BottomNavigationAction className={classes.BNA}  value="/Resume" label="Resume" icon={<BookIcon/>}/>
            {MenuItem}
        </BottomNavigation>
    );
}