import React, {useEffect, useState} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import Card_MC from '@material-ui/core/Card';
import {Typography} from "@material-ui/core";
import {Card, Icon} from 'semantic-ui-react';
import useWindowSize from "../../Components/use-window-size";
import Jumbotron from "react-bootstrap/Jumbotron";
import Container from "react-bootstrap/Container";
import TypeML from "../Images/TypeML.png"

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: "98%",
        marginLeft: "1%",
        display: "flex",
        justifyContent: "space-around"
    },
    CardRoot: {
        flexGrow: 1,
        width: "100%",
        display: "flex",
        justifyContent: "space-evenly"
        // background: 'linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d)'
    },
    GridContainer: {
        marginTop: "1em",
    },
    ContentDiv: {
        textAlign: "left",
        marginLeft: "2em",
        marginRight: "2em",
        marginTop: "2em",
        fontSize: "20px"
    },
    DownloadText: {
        fontSize: "20px",
        fontWeight: "bold",
        textAlign: "center",
        margin: 0
    },
    PDF: {
        color: "red"
    },
    TitleDiv: {
        textAlign: "center"
    },
    cover: {
        // width: "40%",
        minWidth: "500px",
        minHeight: "230px",
        backgroundSize: "contain",
    },
    WorkDiv: {
        display: "flex",
        justifyContent: "space-between",
        flexGrow: "1"
    },
    WorkTitle: {
        display: "flex",
        justifyContent: "center"
    },
    AchievementCard: {
        width: "98%",
    },
    AchievementPadding: {
        margin: "2em",
        backgroundSize: "contain"
    },
    FlexGrow: {
        flexGrow: 1
    }
}));

export default function Type_ML() {
    const classes = useStyles();
    const theme = useTheme();

    const size = useWindowSize();

    const [gridCenterWidth, setGridCenterWidth] = useState(8);

    const setGridWidth = (width) => {
        if (width > 1400) {
            setGridCenterWidth(8)
        } else if (width <= 1400 && width >= 1024) {
            setGridCenterWidth(12)
        } else if (width < 1024 && width >= 500) {
            setGridCenterWidth(12)
        } else if (width < 500) {
            setGridCenterWidth(12)
        }
    };
    useEffect(() => {
        setGridWidth(size.width);
    }, [size])

    return (
        <section className={"HomeDivContent"} style={{backgroundSize: "100% 60vh"}}>
            <div className={clsx(classes.root, "TopDiv")}>
                <Grid container alignItems={'center'} alignContent={'center'} justify={'center'} spacing={3}
                      className={classes.GridContainer}>
                    <Grid item xs={12}>
                        <Jumbotron fluid id={"PageTitle"}>
                            <Container>
                                <Typography variant={"h1"} className={"title-text"}>
                                    Introduction and overview to Machine Learning
                                </Typography>
                            </Container>
                        </Jumbotron>
                    </Grid>
                    <Grid item xs={12}>
                    </Grid>
                    <Grid item xs={gridCenterWidth}>
                        <Card_MC className={classes.AchievementCard}>
                            <div className={classes.WorkTitle}>
                                <Typography variant={"h3"} className={classes.WorkTitle}>
                                    Fundamental Concept
                                </Typography>
                            </div>
                            <div className={classes.AchievementPadding}>
                                <Typography variant={"h5"}>
                                    Big data and more powerful computers have renewed interests in the field of AI.
                                    There are various ML approaches that have been proposed and developed to continue in
                                    pursuit of the AI vision. Figure highlights the different types of machine
                                    learning methods.
                                    <br/><br/>
                                    <div className={classes.WorkTitle}>
                                        <img src={TypeML} width={"70%"}/>
                                    </div>
                                    <br/><br/>A training set of input-output pairs, D = [(x_i, y_i)] Ni = 1, where N is
                                    the number of training examples, xi are inputs and yi are output. The goal is to be
                                    able to map ‘xi’, which is a D-dimensional vector of numbers, to output yi. ‘xi’ are
                                    also called as features, attributes or covariates. ‘xi’ can represent any form of
                                    data, such as height and weight, and be in complex structure object, containing
                                    information representing an image, a sentence, an email message, a time series,
                                    pipeline data, graphs, etc. ‘yi’, the output or the response variable, can also take
                                    multiple forms. If the methods assume the value of ‘yi’ is a categorical or nominal
                                    whose range can be defined by a finite set, then that particular problem is
                                    classification whereas if output is real-valued, then it is said to be regression
                                    problem.
                                    <br/><br/>Another type of Machine learning approach is the unsupervised learning
                                    approach, where the goal changes to finding interesting patterns in the data, which
                                    is also called as knowledge discovery. There are no predefined labels for input
                                    points. Therefore the training set has only inputs, D = [xi] Ni =1. The machine is
                                    not explicitly told to find any particular patterns or use oblivious error metric,
                                    which is comparing the predicted output for input value x given the actual label.
                                    Therefore the problem is less well defined.
                                    <br/><br/>Finally, there is reinforcement learning, though it is less commonly used,
                                    it is useful for learning by performing continuous iterations on the data with a
                                    goal to find a way to map input vectors, xi, to output vectors, yi, in such a way
                                    that maximises rewards accumulated over time.
                                </Typography>
                            </div>
                        </Card_MC>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={"BtmSpc"}>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </section>
    );
}