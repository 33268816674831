import React, {useEffect, useState} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import Card_MC from '@material-ui/core/Card';
import {Typography} from "@material-ui/core";
import {Card,Icon} from 'semantic-ui-react';
import useWindowSize from "../../Components/use-window-size";
import Jumbotron from "react-bootstrap/Jumbotron";
import Container from "react-bootstrap/Container";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: "98%",
        marginLeft: "1%",
        display: "flex",
        justifyContent: "space-around"
    },
    CardRoot: {
        flexGrow: 1,
        width: "100%",
        display: "flex",
        justifyContent: "space-evenly"
        // background: 'linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d)'
    },
    GridContainer: {
        marginTop: "1em",
    },
    ContentDiv: {
        textAlign: "left",
        marginLeft: "2em",
        marginRight: "2em",
        marginTop: "2em",
        fontSize: "20px"
    },
    DownloadText: {
        fontSize: "20px",
        fontWeight: "bold",
        textAlign: "center",
        margin: 0
    },
    PDF: {
        color: "red"
    },
    TitleDiv: {
        textAlign: "center"
    },
    cover: {
        // width: "40%",
        minWidth: "500px",
        minHeight: "230px",
        backgroundSize: "contain",
    },
    WorkDiv: {
        display: "flex",
        justifyContent: "space-between",
        flexGrow: "1"
    },
    WorkTitle: {
        display: "flex",
        justifyContent: "center"
    },
    AchievementCard: {
        width: "98%",
    },
    AchievementPadding: {
        margin: "2em",
        backgroundSize: "contain"
    },
    FlexGrow:{
        flexGrow : 1
    }
}));

export default function IntroToML() {
    const classes = useStyles();
    const theme = useTheme();

    const size = useWindowSize();

    const [gridCenterWidth, setGridCenterWidth] = useState(8);

    const setGridWidth = (width) => {
        if(width > 1400){
            setGridCenterWidth(8)
        }else if(width <= 1400 && width >= 1024){
            setGridCenterWidth(12)
        }else if(width < 1024 && width >= 500){
            setGridCenterWidth(12)
        }else if(width < 500){
            setGridCenterWidth(12)
        }
    };
    useEffect(() => {
        setGridWidth(size.width);
    }, [size])

    return (
        <section className={"HomeDivContent"} style={{backgroundSize: "100% 60vh"}}>
        <div className={clsx(classes.root, "TopDiv")}>
            <Grid container alignItems={'center'} alignContent={'center'} justify={'center'} spacing={3} className={classes.GridContainer}>
                <Grid item xs={12}>
                    <Jumbotron fluid id={"PageTitle"}>
                        <Container>
                            <Typography variant={"h1"} className={"title-text"}>
                                Introduction and overview to Machine Learning
                            </Typography>
                        </Container>
                    </Jumbotron>
                </Grid>
                <Grid item xs={12}>
                </Grid>
                <Grid item xs={gridCenterWidth}>
                    <Card_MC className={classes.AchievementCard}>
                        <div className={classes.WorkTitle}>
                            <Typography variant={"h3"} className={classes.WorkTitle}>
                                Fundamental Concept
                            </Typography>
                        </div>
                        <div className={classes.AchievementPadding}>
                            <Typography variant={"h5"}>
                                The fundamental concept of learning for machines is that machines are learning from data.  In human behavioural terms, learning from experience is labelled as intelligent behaviour. Learning allows flexibility and enables adapting to unique circumstances, therefore learning new tricks. For animals learning is remembering, adapting and generalising: recognising that last time in this situation (saw this data), the action was taken (gave this output) and it worked (correct), or if didn’t work, try something different. Generalising is about recognising the similarity between different situations so that things that applied in one place can be used in another. Learning becomes useful because knowledge can be used in lots of different locations.
                                <br/><br/>Artificial intelligence (AI) is the field of science concerned with the study and design of intelligent machines. [7] Humans define intelligent tasks, as tasks that require skills such as reasoning, planning, learning, perception and problem-solving. Over the last six decades, AI has grown to become multidisciplinary; it encompasses fields like computer science, engineering, psychology, philosophy and more. AI has become so vast that scientists and researchers have divided AI into several sub-disciplines, where each sub-discipline is concerned with different applications, though they may share a similar approach to problem-solving.
                                <br/><br/>John Naisbitt, a famous American writer, visionary and scholar, said: “We are drowning in information and starving for knowledge”.  In the era of big data, where there are about 1 trillion web pages; one hour of video is uploaded to YouTube every second; the genomes of thousands of people, each of which has length of 3.8 X 〖10〗^9 base pairs, have been sequenced by various labs and so on. This deluge of data calls for automated methods of data analysis, which is provided by the use of machine learning methods. [8]
                                <br/><br/>In this blog, the most common and popular sub-discipline of AI is machine learning is discussed. This domain concentrates on solving the most challenging area of AI which is learning. Machine learning has a set of methods that allows automatic detection of patterns in data, then predict results of future data using the pattern uncovered, or to perform other kinds of decision making under uncertainty.
                                <br/><br/>Machine learning (ML) is a core branch of AI that aims to give computers the ability to learn without being explicitly programmed. For complex datasets, it becomes impossible to directly interpret observations or to understand the effect of specific changes. Machine learning addresses this problem by replacing deduction through physical modelling by extracting the underlying relations directly from observed data: Statistical nonparametric models, for example, do not assume a fixed functional dependency, and the complexity of the model is adjusted as more data are observed. These models serve as machinery to handle high dimensional data, being very flexible and thus applicable to many different problems. They are based on elementary assumptions about the structure of the data, not the underlying physical reality. [9] Machine learning allows the computer to perform tasks by learning from experience something that humans and another intelligent animal do naturally. Algorithms part of Machine learning use computational methods as a model to learn rather than be dependent on a predetermined equation. The performance of these algorithms improves as the number of available sample increases, as algorithms are then better able to adapt to the data.
                                <br/><br/>ML has many subfields and applications, including statistical learning methods, neural networks, instance-based learning, genetic algorithms, data mining, image recognition, natural language processing (NLP), computational learning theory, inductive logic programming, and reinforcement learning. Essentially, ML is the capability of software or a machine to improve the performance of tasks through exposure to data and experience. A typical ML model first learns the knowledge from the data it is exposed to and then applies this knowledge to provide predictions about emerging (future) data.
                                <br/><br/>ML has several applications such as credit card fraud detection, handwritten digit recognition, recommendation on websites, credit scoring, financial trading, Image processing, targeted marketing, sentiment analysis, climate monitoring, crime pattern detection, useful drug analysis and DNA sequencing.  The Internet, e-commerce, e-banking, point-of-sale devices, intelligent machines and bar-code reader have allowed for electronic data capture to become inexpensive and ubiquitous. Data generated are stored specialised data warehouses and data marts. Another field that has grown development exponentially due to the intent of these data warehouse is data mining; it houses techniques and methods to efficiently extract useful information from massive datasets, thus requiring intelligent and efficient algorithms.
                                <br/><br/>Algorithms which are part of the machine learning methods are assessed based on their accuracy, and now with the advent of Big Data, their computational complexity is also given a huge importance. There is a tradeoff between the complexity and accuracy of the model. A model with a high degree of computational complexity is applied to a large dataset; there are high probabilities of problems arising. Complexity has two components, first is the complex nature of training and second is the model's complexity while applying the trained algorithms to produce a result. The training process is performed while building the model, thus before deploying, so the training time is not as crucial as the time taken by the model to produce a result. Time taken to produce the result determines the type of applications the model can be applied to, for example only a model with a shorter period can be applied to the real-time system. The computational load is also important, especially if the intelligence is implemented in a battery-operated system. As lower the load, the longer the battery will last.
                            </Typography>
                        </div>
                    </Card_MC>
                </Grid>
                <Grid item xs={12}>
                    <div className={"BtmSpc"}>
                    </div>
                </Grid>
            </Grid>
        </div>
        </section>
    );
}