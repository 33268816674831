import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from "@material-ui/core/Button";
import history from "../Components/history";
import clsx from "clsx";
import Jumbotron from "react-bootstrap/Jumbotron";
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexGrow: 1,
    },
    Avatar: {
        width: theme.spacing(7),
        height: theme.spacing(7),
        marginRight: "10px",
    },
    Title: {
        fontSize: "48px",
        lineHeight: "48px",
        margin: "0 0 5px",
        fontWeight: "900",
        color: "white",
    },
    TagLine: {
        fontSize: "24px",
        marginTop: "2px",
        fontWeight: "600",
        color: "white",
    },
    Description: {
        fontSize: "20px",
        lineHeight: 1.5,
        color: "white",
    },
    Jumbotron:{
        backgroundColor : "transparent"
    },
    Divider:{
        height: "5px",
        backgroundColor : "white",
        marginTop : "15px"
    }
}));


export default function Home() {
    const classes = useStyles();

    return (

        <section className={"HomePageDivNew swoosh"}>
            <CssBaseline />
            <div className={"TopDiv_Home"}>
                <Container maxWidth="sm" style={{height:'90vh'}}>
                    <Jumbotron fluid className={classes.Jumbotron}>
                        <Container style={{height:'100%', marginTop:'45%'}}>
                            <h1 className={classes.Title}>Mehul Gupta</h1>
                            <Divider className={classes.Divider}/>
                            <p className={classes.TagLine}>
                                Internet of Things & Artificial Intelligence Solutions Lead
                            </p>
                            {/*<p className={clsx(classes.Description, "DesktopOnly")}>*/}
                            {/*    Digital Transformation Lead with close to 3 years’ experience in this field spearheading*/}
                            {/*    projects to optimise revenue growth, profitability and market share in the international*/}
                            {/*    Oil & Gas, Semicon, Manufacturing, Academic and Telecommunication sectors.*/}
                            {/*</p>*/}
                            <Button variant="contained" color="primary" onClick={() => {
                                history.push("/Resume")
                            }}> Know More</Button>
                        </Container>
                    </Jumbotron>
                </Container>

            </div>
        </section>
    )
};