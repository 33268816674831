import {Label} from "semantic-ui-react";
import React from "react";
import ML from './Images/MachineLearning.jpg'
import TypeML from './Images/TypeML.png'
import RPT from '../Projects/Image/RPT.png'
import Button from "@material-ui/core/Button";
import history from "../Components/history";
export default function BlogItems() {

    const extraMap = (content,ButtonLink) => (
        <div className={"extraContent"}>
        <Label.Group circular color='teal'>
            {
                content.map((contentPart) => (
                    <Label as='a' size={"large"}>
                        {contentPart}
                    </Label>
                ))
            }
        </Label.Group>
        <Button variant="contained" color="primary" onClick={() => {history.push(ButtonLink)}}>Read More</Button>
        </div>
    )

    const LabelJSON_AI = (
        {
            as: 'a',
            color: 'blue',
            content: 'A.I.',
            icon: 'info circle',
            ribbon: true,
        }
    )

    const LabelJSON_IoT = (
        {
            as: 'a',
            color: 'black',
            content: 'IoT',
            icon: 'info circle',
            ribbon: true,
        }
    )

    const LabelJSON_IoT_AR = (
        {
            as: 'a',
            color: 'green',
            content: 'IoT & AR',
            icon: 'info circle',
            ribbon: true,
        }
    )

    const LabelJSON_IoT_AR_AI = (
        {
            as: 'a',
            color: 'green',
            content: 'IoT & AR & AI',
            icon: 'info circle',
            ribbon: true,
        }
    )

    const style = (
        {
            height: '200px',
            width: '300px'
        }
    )

    const style_width = (
        {
            height: '200px',
            width: '250px',
            marginRight: '50px'
        }
    )
    const items = [
        {
            childKey: 0,
            image: {
                src: ML, alt: "AI", label: LabelJSON_AI, style: style, className:"BlogImageDiv"
            },
            header: 'Introduction and overview to Machine Learning',
            // className: 'middle aligned content',
            description: 'The fundamental concept of learning for machines is that machines are learning from data.  In human behavioural terms, learning from experience is labelled as intelligent behaviour. Learning allows flexibility and enables adapting to unique circumstances, therefore learning new tricks. For animals learning is remembering, adapting and generalising: recognising that last time in this situation (saw this data), the action was taken (gave this output) and it worked (correct), or if didn’t work, try something different. Generalising is about recognising the similarity between different situations so that things that applied in one place can be used in another.',
            color: 'red',
            extra: extraMap("Machine Learning,Introduction,Overview".split(','),"/IntroToML"),
        },
        {
            childKey: 1,
            image: {
                src: TypeML, alt: "IoT", label: LabelJSON_AI, style: style_width, className:"BlogImageDiv"
            },
            header: 'Types of Machine Learning',
            description: 'Big data and more powerful computers have renewed interests in the field of AI. There are various ML approaches that have been proposed and developed to continue in pursuit of the AI vision.',
            color: 'blue',
            extra: extraMap("Machine Learning,Introduction,Overview".split(','),"/Type_ML"),
        }
    ]

    return items;
}
