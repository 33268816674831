import React, {useEffect, useState} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import Card_MC from '@material-ui/core/Card';
import {Typography} from "@material-ui/core";
import {Card,Icon} from 'semantic-ui-react';
import python from 'devicon/icons/python/python-original-wordmark.svg'
import java from 'devicon/icons/java/java-original-wordmark.svg'
import javascript from 'devicon/icons/javascript/javascript-original.svg'
import c_lang from 'devicon/icons/c/c-original.svg'
import cplusplus from 'devicon/icons/cplusplus/cplusplus-original.svg'
import android from 'devicon/icons/android/android-original-wordmark.svg'
import apache from 'devicon/icons/apache/apache-line-wordmark.svg'
import atom from 'devicon/icons/atom/atom-original-wordmark.svg'
import css3 from 'devicon/icons/css3/css3-original-wordmark.svg'
import git from 'devicon/icons/git/git-original-wordmark.svg'
import nodejs from 'devicon/icons/nodejs/nodejs-original-wordmark.svg'
import npm from 'devicon/icons/npm/npm-original-wordmark.svg'
import jquery from 'devicon/icons/jquery/jquery-original-wordmark.svg'
import php from 'devicon/icons/php/php-original.svg'
import react from 'devicon/icons/react/react-original-wordmark.svg'
import sass from 'devicon/icons/sass/sass-original.svg'
import html5 from 'devicon/icons/html5/html5-original-wordmark.svg'
import intellij from 'devicon/icons/intellij/intellij-original-wordmark.svg'
import bitbucket from 'devicon/icons/bitbucket/bitbucket-original-wordmark.svg'
import github from 'devicon/icons/github/github-original-wordmark.svg'
import sourcetree from 'devicon/icons/sourcetree/sourcetree-original-wordmark.svg'
import bootstrap from 'devicon/icons/bootstrap/bootstrap-plain-wordmark.svg'
import linux from 'devicon/icons/linux/linux-original.svg'
import tomcat from 'devicon/icons/tomcat/tomcat-original-wordmark.svg'
import mysql from 'devicon/icons/mysql/mysql-original-wordmark.svg'
import postgresql from 'devicon/icons/postgresql/postgresql-original-wordmark.svg'
import useWindowSize from "../Components/use-window-size";
import Jumbotron from "react-bootstrap/Jumbotron";
import Container from "react-bootstrap/Container";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: "98%",
        marginLeft: "1%",
        display: "flex",
        justifyContent: "space-around"
    },
    CardRoot: {
        flexGrow: 1,
        width: "100%",
        display: "flex",
        justifyContent: "space-evenly"
        // background: 'linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d)'
    },
    GridContainer: {
        marginTop: "1em",
    },
    ContentDiv: {
        textAlign: "left",
        marginLeft: "2em",
        marginRight: "2em",
        marginTop: "2em",
        fontSize: "20px"
    },
    DownloadText: {
        fontSize: "20px",
        fontWeight: "bold",
        textAlign: "center",
        margin: 0
    },
    PDF: {
        color: "red"
    },
    TitleDiv: {
        textAlign: "center"
    },
    cover: {
        // width: "40%",
        minWidth: "500px",
        minHeight: "230px",
        backgroundSize: "contain",
    },
    WorkDiv: {
        display: "flex",
        justifyContent: "space-between",
        flexGrow: "1"
    },
    WorkTitle: {
        display: "flex",
        justifyContent: "center"
    },
    AchievementCard: {
        width: "98%",
    },
    AchievementPadding: {
        margin: "2em",
        backgroundSize: "contain"
    },
    FlexGrow:{
        flexGrow : 1
    }
}));

export default function Skill() {
    const classes = useStyles();
    const theme = useTheme();

    const items = [
        {image: python, color:'blue'},
        {image: java, color:'blue'},
        {image: javascript, color:'blue'},
        {image: c_lang, color:'blue'},
        {image: cplusplus, color:'blue'},
        {image: android, color:'blue'},
        {image: css3, color:'blue'},
        {image: jquery, color:'blue'},
        {image: php, color:'blue'},
        {image: react, color:'blue'},
        {image: sass, color:'blue'},
        {image: html5, color:'blue'},
    ]

    const items_tools = [
        {image: apache, color:'blue'},
        {image: atom, color:'blue'},
        {image: git, color:'blue'},
        {image: nodejs, color:'blue'},
        {image: npm, color:'blue'},
        {image: intellij, color:'blue'},
        {image: bitbucket, color:'blue'},
        {image: github, color:'blue'},
        {image: sourcetree, color:'blue'},
        {image: bootstrap, color:'blue'},
        {image: linux, color:'blue'},
        {image: tomcat, color:'blue'},
    ]

    const items_db = [
        {image: mysql, color:'blue'},
        {image: postgresql, color:'blue'},
        {image: "./res/Image/microsoft-sql-server.svg", color:'blue'},
    ]


    const size = useWindowSize();

    const [gridCenterWidth, setGridCenterWidth] = useState(8);
    const [cardNumber, setCardNumber] = useState(4);

    const setGridWidth = (width) => {
        if(width > 1400){
            setGridCenterWidth(8)
            setCardNumber(6)
        }else if(width <= 1400 && width >= 1024){
            setGridCenterWidth(12)
            setCardNumber(6)
        }else if(width < 1024 && width >= 500){
            setGridCenterWidth(12)
            setCardNumber(4)
        }else if(width < 500){
            setGridCenterWidth(12)
            setCardNumber(4)
        }
    };
    useEffect(() => {
        setGridWidth(size.width);
    }, [size])

    return (
        <section className={"HomeDivContent"} style={{backgroundSize: "100% 60vh"}}>
        <div className={clsx(classes.root, "TopDiv")}>
            <Grid container alignItems={'center'} alignContent={'center'} justify={'center'} spacing={3} className={classes.GridContainer}>
                <Grid item xs={12}>
                    <Jumbotron fluid id={"PageTitle"}>
                        <Container>
                            <Typography variant={"h1"} className={"title-text"}>
                                Skills
                            </Typography>
                        </Container>
                    </Jumbotron>
                </Grid>
                <Grid item xs={12}>
                </Grid>
                <Grid item xs={gridCenterWidth}>
                    <Card_MC className={classes.AchievementCard}>
                        <div className={classes.WorkTitle}>
                            <Typography variant={"h3"} className={classes.WorkTitle}>
                                Programming
                            </Typography>
                        </div>
                        <div className={classes.AchievementPadding}>
                            <Card.Group className={"single-brand"} itemsPerRow={cardNumber} centered items={items}/>
                        </div>
                    </Card_MC>
                </Grid>
                <Grid item xs={gridCenterWidth}>
                    <Card_MC className={classes.AchievementCard}>
                        <div className={classes.WorkTitle}>
                            <Typography variant={"h3"} className={classes.WorkTitle}>
                                Tools
                            </Typography>
                        </div>
                        <div className={classes.AchievementPadding}>
                            <Card.Group className={"single-brand"} itemsPerRow={cardNumber} centered items={items_tools}/>
                        </div>
                    </Card_MC>
                </Grid>
                <Grid item xs={gridCenterWidth}>
                    <Card_MC className={classes.AchievementCard}>
                        <div className={classes.WorkTitle}>
                            <Typography variant={"h3"} className={classes.WorkTitle}>
                                Database
                            </Typography>
                        </div>
                        <div className={classes.AchievementPadding}>
                            <Card.Group className={"single-brand"} itemsPerRow={cardNumber} centered items={items_db}/>
                        </div>
                    </Card_MC>
                </Grid>
                <Grid item xs={12}>
                    <div className={"BtmSpc"}>
                    </div>
                </Grid>
            </Grid>
        </div>
        </section>
    );
}