import React, {useEffect, useState} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import Card_MC from '@material-ui/core/Card';
import {Button, Typography} from "@material-ui/core";
import Card from '@material-ui/core/Card';
import useWindowSize from "../../Components/use-window-size";
import Jumbotron from "react-bootstrap/Jumbotron";
import Container from "react-bootstrap/Container";
import Looks4Icon from '@material-ui/icons/Looks4';
import Looks6Icon from '@material-ui/icons/Looks6';
import W_PNG from '../Images/W.png'
import NB_SVG from '../Images/nb.svg'
import UmpireCard from "./UmpireCard";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: "98%",
        marginLeft: "1%",
        display: "flex",
        justifyContent: "space-around"
    },
    CardRoot: {
        flexGrow: 1,
        width: "100%",
        display: "flex",
        justifyContent: "space-evenly",
        flexWrap : "wrap"
        // background: 'linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d)'
    },
    GridContainer: {
        marginTop: "1em",
    },
    ContentDiv: {
        textAlign: "left",
        marginLeft: "2em",
        marginRight: "2em",
        marginTop: "2em",
        fontSize: "20px"
    },
    DownloadText: {
        fontSize: "20px",
        fontWeight: "bold",
        textAlign: "center",
        margin: 0
    },
    PDF: {
        color: "red"
    },
    TitleDiv: {
        textAlign: "center"
    },
    cover: {
        // width: "40%",
        minWidth: "500px",
        minHeight: "230px",
        backgroundSize: "contain",
    },
    WorkDiv: {
        display: "flex",
        justifyContent: "space-between",
        flexGrow: "1"
    },
    WorkTitle: {
        display: "flex",
        justifyContent: "center"
    },
    AchievementCard: {
        width: "98%",
    },
    AchievementPadding: {
        margin: "2em",
        backgroundSize: "contain"
    },
    FlexGrow: {
        flexGrow: 1
    }
}));

export default function UmpirePage() {
    const classes = useStyles();
    const theme = useTheme();

    const size = useWindowSize();

    const [gridCenterWidth, setGridCenterWidth] = useState(8);

    const setGridWidth = (width) => {
        if (width > 1400) {
            setGridCenterWidth(8)
        } else if (width <= 1400 && width >= 1024) {
            setGridCenterWidth(12)
        } else if (width < 1024 && width >= 500) {
            setGridCenterWidth(12)
        } else if (width < 500) {
            setGridCenterWidth(12)
        }
    };
    useEffect(() => {
        setGridWidth(size.width);
    }, [size])

    return (
        <section className={"HomeDivContent"} style={{backgroundSize: "100% 60vh"}}>
            <div className={clsx(classes.root, "TopDiv")}>
                <Grid container alignItems={'center'} alignContent={'center'} justify={'center'} spacing={3}
                      className={classes.GridContainer}>
                    <Grid item xs={12}>
                        <Jumbotron fluid id={"PageTitle"}>
                            <Container>
                                <Typography variant={"h1"} className={"title-text"}>
                                    Cricket Umpire
                                </Typography>
                            </Container>
                        </Jumbotron>
                    </Grid>
                    <Grid item xs={12}>
                    </Grid>
                    <Grid item xs={gridCenterWidth}>
                        <Card_MC className={classes.AchievementCard}>
                            <div className={classes.WorkTitle}>
                                <Typography variant={"h3"} className={classes.WorkTitle}>
                                    Click on start button
                                </Typography>
                            </div>
                            <div className={classes.AchievementPadding}>
                                <Button variant="contained" color="primary" onClick={() => {window.init()}}>
                                Start
                                </Button>
                                <Button variant="contained" color="primary" onClick={() => {window.stopUmpire()}}>
                                Stop
                                </Button>
                            </div>
                        </Card_MC>
                    </Grid>
                    <Grid item xs={gridCenterWidth}>
                        <Card className={classes.CardRoot}>
                            <UmpireCard CardType={"Six"} CardIcon={<Looks6Icon/>} CardValue={"divSix"} CardLink={"divSixCard"}/>
                            <UmpireCard CardType={"Four"} CardIcon={<Looks4Icon/>} CardValue={"divFour"} CardLink={"divFourCard"}/>
                            <UmpireCard CardType={"Wicket"} CardIcon={<img src={W_PNG} width={"55%"}/>} CardValue={"divWicket"} CardLink={"divWicketCard"}/>
                            <UmpireCard CardType={"No Ball"} CardIcon={<img src={NB_SVG} width={"150%"}/>}  CardValue={"divNoBall"} CardLink={"divNoBallCard"}/>
                       </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={"BtmSpc"}>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </section>
    );
}