import React from 'react';
import BottomNavigationMenu from "./Components/BottomNavigationMenu"
import './App.css';
import Routes from "./Components/Routes";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'semantic-ui-css/semantic.min.css'
import TopBar from "./Components/Topbar";

function App() {
    return (
        <div>
            <TopBar />
            {/*<BottomNavigationMenu/>*/}
            <Routes/>
        </div>
    );
}

export default App;
