import React, {useEffect, useState} from "react";
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import Card_MC from '@material-ui/core/Card';
import {Typography} from "@material-ui/core";
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import {Card} from 'semantic-ui-react';
import Box from '@material-ui/core/Box';
import useWindowSize from '../Components/use-window-size';
import history from "../Components/history";
import Jumbotron from "react-bootstrap/Jumbotron";
import Container from "react-bootstrap/Container";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: "98%",
        marginLeft: "1%",
        display: "flex",
        flexWrap: "wrap"
    },
    CardRoot: {
        flexGrow: 1,
        width: "100%",
        display: "flex",
        justifyContent: "space-evenly",
        // background: 'linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d)'
    },
    GridContainer: {
        marginTop: "1em",
    },
    ContentDiv: {
        textAlign: "left",
        marginLeft: "2em",
        marginRight: "2em",
        marginTop: "2em",
        fontSize: "20px"
    },
    DownloadText: {
        fontSize: "20px",
        fontWeight: "bold",
        textAlign: "center",
        margin: 0
    },
    PDF: {
        color: "red"
    },
    TitleDiv: {
        textAlign: "center"
    },
    cover: {
        objectFit: "contain",
    },
    WorkDiv: {
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap"
    },
    WorkTitle: {
        display: "flex"
    },
    AchievementCard: {
        width: "98%",
        marginLeft: "1%",
    },
    AchievementPadding: {
        margin: "2em"
    }
}));

export default function Work() {
    const classes = useStyles();
    const theme = useTheme();

    const items = [
        {
            header: 'Applied ML Algorithms for failure prediction, process optimisation and time to failure',
            color: 'blue'
        },
        {header: 'Created 25+ new accounts across industry verticals', color: 'blue'},
        {header: 'Developed partnership with key partners in different IoT verticals, from IT to OT', color: 'blue'},
        {
            header: 'Scaled engineering and software technical team size from 3 to 30 high-performing personnel in just two years',
            color: 'blue'
        },
        {
            header: 'Increased global team presence from Singapore to Malaysia, Indonesia and Thailand in three years',
            color: 'blue'
        },
        {header: 'Won the “Top PTC IoT Partner for South Pacific RIM” for the year 2018 and 2019', color: 'blue'},
        {
            header: 'Developed presentations, marketing collateral and capability statements for prospective clients',
            color: 'blue'
        },
        {
            header: 'Utilised in-depth knowledge to create differentiators to set CAD-IT Consultants apart from competitors',
            color: 'blue'
        },
        {
            header: 'Researched client’s organisation, processes and business strategies and engaged with key decision-makers',
            color: 'blue'
        },
        {
            header: 'Identified new customer needs, business and technology trends for potential opportunities across Malaysia, Singapore, Indonesia, China and Thailand',
            color: 'blue'
        },
        {
            header: 'Identified client needs, presented value for money solutions to clients and defined the value proposition, whilst customising multi-product, multi-vendor solutions from CAD-IT Consultants and partner network',
            color: 'blue'
        },
        {header: 'Delivered training to our major clients, several research and academic institutions', color: 'blue'},
        {
            header: 'Performed creation and optimisation of Python Script for migrating and transforming data for various critical industrial machines from a variety of industries',
            color: 'blue'
        },
        {
            header: 'Contributing member for the development of Fundamentals of IoT Course for several academic institutions in Singapore',
            color: 'blue'
        },
        {
            header: 'Presently leading a multi-national team to deliver several full-scale factory level digital transformation projects across South East Asia',
            color: 'blue'
        },
        {
            header: 'Supported negotiations and facilitated the acquisition of management approval for bids, bid investments, pricing and contract terms and conditions',
            color: 'blue'
        },
    ]

    const size = useWindowSize();

    const [gridCenterWidth, setGridCenterWidth] = useState(8);
    const [cardNumber, setCardNumber] = useState(4);
    const setGridWidth = (width) => {
        if (width > 1400) {
            setGridCenterWidth(8)
            setCardNumber(4)
        } else if (width <= 1400 && width >= 1024) {
            setGridCenterWidth(12)
            setCardNumber(4)
        } else if (width < 1024 && width >= 500) {
            setGridCenterWidth(12)
            setCardNumber(2)
        } else if (width < 500) {
            setGridCenterWidth(12)
            setCardNumber(1)
        }
        //console.log(width)
    };
    useEffect(() => {
        setGridWidth(size.width);
    }, [size])

    return (
        <section className={"HomeDivContent"} style={{backgroundSize: "100% 60vh"}}>
            <Box className={clsx(classes.root, "TopDiv")}>
                <Grid container alignItems={'center'} alignContent={'center'} justify={'center'} spacing={3}
                      className={classes.GridContainer}>
                    <Grid item xs={12}>
                        <Jumbotron fluid id={"PageTitle"}>
                            <Container>
                                <Typography variant={"h1"} className={"title-text"}>
                                    Work Experience
                                </Typography>
                            </Container>
                        </Jumbotron>
                    </Grid>
                    <Grid item xs={12}>
                    </Grid>
                    <Grid item xs={gridCenterWidth}>
                        <Card_MC className={classes.root}>
                            <CardMedia
                                component="img"
                                alt="CADIT"
                                height="300"
                                className={classes.cover}
                                image="./res/Image/CADIT_CONSULTANT.png"
                                title="Live from space album cover"
                            />
                            <div className={classes.ContentDiv}>
                                <div className={classes.WorkDiv}>
                                    <div className={classes.WorkTitle}>
                                        <Typography variant={"h4"} className={classes.WorkTitle}>
                                            CAD-IT CONSULTANTS (M) SDN BHD
                                        </Typography>
                                    </div>
                                    <div className={classes.WorkTitle}>
                                        <LocationOnIcon style={{fontSize: 40, paddingBottom: "8px"}}/>
                                        <Typography variant={"h5"} className={classes.WorkTitle}>
                                            Kuala Lumpur
                                        </Typography>
                                    </div>
                                    <div className={classes.WorkTitle}>
                                        <CalendarTodayIcon style={{fontSize: 40, paddingBottom: "8px"}}/>
                                        <Typography variant={"h5"} className={classes.WorkTitle}>
                                            2017 - Present
                                        </Typography>
                                    </div>
                                </div>
                                <p>
                                    Reporting to the Program Director for Enterprise Solutions (Global); leading the
                                    technical effort for IoT and AI project delivery, responsible for supporting
                                    technical
                                    pre-sales for winning IoT projects and supporting commercial business development
                                    strategy for S.E. Asia.
                                </p>
                                <p>
                                    Also, help to identify opportunities by understanding customer requirements and then
                                    designing complete system architecture, to win projects and continually expand the
                                    regional customer base across diverse industries, from oil and gas, Semicon,
                                    manufacturing, academic, public sector, retail and telecommunication.
                                </p>
                            </div>
                        </Card_MC>
                    </Grid>

                    <Grid item xs={gridCenterWidth} alignItems={"center"}>
                        <Card_MC className={classes.AchievementCard}>
                            <div className={classes.AchievementPadding}>
                                <Card.Group className={"work-header"} itemsPerRow={cardNumber} centered items={items}/>
                            </div>
                        </Card_MC>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={"BtmSpc"}>
                        </div>
                    </Grid>
                </Grid>
            </Box>
        </section>
    );
}