import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import clsx from "clsx";
import Toolbar from '@material-ui/core/Toolbar';
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import GitHubIcon from '@material-ui/icons/GitHub';
import EmailIcon from '@material-ui/icons/Email';
import IconButton from "@material-ui/core/IconButton";
import history from "./history";
import Popper from '@material-ui/core/Popper';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';

export default function TopBar() {
    const [background, setBackground] = useState("transparent");
    const [updateNote, setUpdateNote] = useState('Hello');

    // const [isVideo, setVideo] = useState(false);

    function changeStyle() {
        //console.log(window.pageYOffset)
        if (window.pageYOffset > 50) {
            setBackground("color");
        } else {
            setBackground("transparent");
        }
    }

    useEffect(() => {
        function watchScroll() {
            window.addEventListener("scroll", changeStyle);
        }

        watchScroll();
        return () => {
            window.removeEventListener("scroll", changeStyle);
        };
    });


    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex'
        },
        appBarTransparent: {
            background: "transparent",
            boxShadow: "none"
        },
        appBarColor: {
            background: "linear-gradient(45deg, #405de6,#c13584, #e1306c, #fd1d1d)",
            '@media (max-width: 570px)' : {
                background: "linear-gradient(#12013A,#12013A)"
            },
            boxShadow: "none"
        },
        Avatar: {
            width: theme.spacing(6),
            height: theme.spacing(6),
            cursor: "pointer",
        },
        toolBar: {
            justifyContent: "space-between"
        },
        socialBar: {
            '& svg': {
                color: "white"
            }
        },
        NavItem:{
            marginTop : '15px',
            color: "white",
            '@media (max-width: 570px)' : {
                marginTop : '0px'
            },
        },
        NavItemLink:{
            '@media (max-width: 570px)' : {
                marginTop : '0px',
                height : '50px'
            },
        },
        menuTextDesktop:{
            display : 'none',
            '@media (max-width: 570px)' : {
                color : "white",
                fontSize : '20px',
                margin : '10px',
                display: 'inline'
            },
        }
    }));

    const classes = useStyles();


// device detection
    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
        || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) {
        window.isMobile = true;
    }


    const [expanded, setExpanded] = useState(false);

    //Navigation
    const handleChange = (newValue) => {
        history.push(newValue);
        setExpanded(expanded ? false : "expanded")
    };

    return (
        <div id="topBar" className={clsx(classes.root)}>
            <CssBaseline/>
            <AppBar position="fixed"
                    className={clsx({[classes.appBarColor]: background === "color"}, {[classes.appBarTransparent]: background === "transparent"})}>
                <Container>
                    <Navbar expand="sm" expanded={expanded} className={"navbarTop"}>
                        <Navbar.Brand onClick={() => {
                            handleChange("/")
                        }} className="app-title-brand">
                            <Avatar alt="Mehul Gupta" className={classes.Avatar} src="./res/Image/Mehul_Gupta.png"></Avatar>
                            {/*<span className={"MuiTypography-h3 app-title-text app-title-brand"}>Mehul</span>*/}
                        </Navbar.Brand>
                        <Navbar.Toggle onClick={() => setExpanded(expanded ? false : "expanded")} aria-controls="basic-navbar-nav" style={{backgroundColor: 'white'}}/>
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className={"ml-auto"}>
                                <Nav.Link className={classes.NavItemLink} onClick={() => {
                                    handleChange("/")
                                }}>
                                    <Typography variant="h3" className={"app-title-text"}>
                                        Home
                                    </Typography>
                                </Nav.Link>
                                <Nav.Link className={classes.NavItemLink} onClick={() => {
                                    handleChange("/Blog")
                                }}>
                                    <Typography variant="h3" className={"app-title-text"}>
                                        Blog
                                    </Typography>
                                </Nav.Link>
                                <Nav.Link className={classes.NavItemLink} onClick={() => {
                                    handleChange("/Project")
                                }}>
                                    <Typography variant="h3" className={"app-title-text"}>
                                        Projects
                                    </Typography>
                                </Nav.Link>
                                <NavDropdown style={{maxHeight: '55px'}}
                                             title={<span className={"MuiTypography-h3 app-title-text"}>Resume</span>}
                                             id="collasible-nav-dropdown-resume">
                                    <NavDropdown.Item  onClick={() => {
                                        handleChange("/Resume")
                                    }}>About Me</NavDropdown.Item>
                                    <NavDropdown.Divider/>
                                    <NavDropdown.Item  onClick={() => {
                                        handleChange("/Work")
                                    }}>Work Experience</NavDropdown.Item>
                                    <NavDropdown.Divider/>
                                    <NavDropdown.Item  onClick={() => {
                                        handleChange("/Education")
                                    }}>Education</NavDropdown.Item>
                                    <NavDropdown.Divider/>
                                    <NavDropdown.Item  onClick={() => {
                                        handleChange("/Skill")
                                    }}>Skills</NavDropdown.Item>
                                    <NavDropdown.Divider/>
                                    <NavDropdown.Item  onClick={() => {
                                        handleChange("/Award")
                                    }}>Award</NavDropdown.Item>
                                </NavDropdown>
                                <Nav.Link className={classes.NavItemLink} href={'https://www.linkedin.com/in/mehulgupta7machinelearning'}>
                                    <LinkedInIcon className={classes.NavItem} style={{fontSize: 35}}/>
                                    <span className={classes.menuTextDesktop}>LinkedIn</span>
                                </Nav.Link>
                                <Nav.Link className={classes.NavItemLink} href={'https://github.com/mg-pool'}>
                                    <GitHubIcon className={classes.NavItem} style={{fontSize: 35}}/>
                                    <span className={classes.menuTextDesktop}>GitHub</span>
                                </Nav.Link>
                                <Nav.Link className={classes.NavItemLink} href={'mailto:mehulge@gmail.com'}>
                                    <EmailIcon className={classes.NavItem} style={{fontSize: 35}}/>
                                    <span className={classes.menuTextDesktop}>Email</span>
                                </Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                </Container>
            </AppBar>
        </div>
    )
};