import React from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import clsx from "clsx";
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import Button from "@material-ui/core/Button";
const useStyles = makeStyles((theme) => ({
    root: {
        height: "370px",
        width: "250px",
        display: "flex",
        flexDirection: "column",
        margin: '1.5em',
        '& > *': {
            margin: theme.spacing(1),
        },
        '&:hover':{
            boxShadow: "0px 10px 30px rgba(57, 56, 61, 0.205) !important",
            borderRadius: ".28571429rem !important"
        }
    },
    Image:{
        marginLeft: "50px",
        marginTop: "15px",
        height: "150px",
        width: "150px"
    },
    Text:{
        marginLeft: "0px",
        marginTop: "0px",
        height: "80px",
        width: "250px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    },
    TextFont:{
        font: "normal normal normal 36px/1.2em georgia,palatino,'book antiqua','palatino linotype',serif"
    },
    Divider:{
        marginLeft: "100px",
        marginTop: "0px",
        height: "40px",
        width: "50px",
        '& > svg': {
            width: "100%",
            height: "100%"
        },
    },
    pink: {
        color: 'white',
        background: 'linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d)',
        width: "100%",
        height: "100%",
        '& > svg': {
            width: "80%",
            height: "80%"
        },
    },
    Icon: {
        width: "70%",
        height: "70%"
    },
    Button:{
        textAlign: "center"
    }
}));

export default function UmpireCard(props) {
    const classes = useStyles();
    const theme = useTheme();
    return (
        <div className={clsx(classes.root,"MuiPaper-rounded","MuiPaper-elevation1")} id={props.CardLink}>
            <div className={clsx(classes.Image)}>
                <Avatar className={classes.pink}>
                    {props.CardIcon}
                </Avatar>
            </div>
            <div className={clsx(classes.Text)}>
                <Typography variant="h4" align={"center"} className={classes.TextFont}>
                    {props.CardType}
                </Typography>
            </div>
            <div className={clsx(classes.Divider)}>
                <DragHandleIcon />
            </div>
            <div className={clsx(classes.Button)}>
                <Typography variant="h4" align={"center"} className={classes.TextFont} id={props.CardValue}>
                    0.00
                </Typography>
            </div>
        </div>
    );
}