import React, {useEffect, useState} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import Card_MC from '@material-ui/core/Card';
import {Typography} from "@material-ui/core";
import {Card} from 'semantic-ui-react';
import { Item } from 'semantic-ui-react';
import useWindowSize from "../Components/use-window-size";
import BlogItems from "./BlogItems";
import Jumbotron from "react-bootstrap/Jumbotron";
import Container from "react-bootstrap/Container";
import { Menu } from 'semantic-ui-react';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: "98%",
        marginLeft: "1%",
        display: "flex",
        justifyContent: "space-around"
    },
    CardRoot: {
        flexGrow: 1,
        width: "100%",
        display: "flex",
        justifyContent: "space-evenly"
        // background: 'linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d)'
    },
    GridContainer: {
        marginTop: "1em",
    },
    ContentDiv: {
        textAlign: "left",
        marginLeft: "2em",
        marginRight: "2em",
        marginTop: "2em",
        fontSize: "20px"
    },
    DownloadText: {
        fontSize: "20px",
        fontWeight: "bold",
        textAlign: "center",
        margin: 0
    },
    PDF: {
        color: "red"
    },
    TitleDiv: {
        textAlign: "center"
    },
    cover: {
        // width: "40%",
        minWidth: "500px",
        minHeight: "230px",
        backgroundSize: "contain",
    },
    WorkDiv: {
        display: "flex",
        justifyContent: "space-between",
        flexGrow: "1"
    },
    WorkTitle: {
        display: "flex",
        justifyContent: "center"
    },
    AchievementCard: {
        width: "98%",
    },
    AchievementPadding: {
        margin: "2em"
    },
    FlexGrow: {
        flexGrow: 1
    }
}));

export default function Blog() {
    const classes = useStyles();
    const theme = useTheme();
    const items = BlogItems();
    const size = useWindowSize();
    const [gridCenterWidth, setGridCenterWidth] = useState(8);
    const [cardNumber, setCardNumber] = useState(4);
    const [itemCard, setItemCard] = useState(items);

    const setGridWidth = (width) => {
        if (width > 1400) {
            setGridCenterWidth(8)
            setCardNumber(3)
        } else if (width <= 1400 && width >= 1150) {
            setGridCenterWidth(12)
            setCardNumber(3)
        } else if (width < 1150 && width >= 500) {
            setGridCenterWidth(12)
            setCardNumber(2)
        } else if (width < 500) {
            setGridCenterWidth(12)
            setCardNumber(1)

        }
    };
    useEffect(() => {
        setGridWidth(size.width);
    }, [size])

    const [activeItem, setActiveItem] = useState('All');
    const handleItemClick = (event, newValue) => {
        setActiveItem(newValue.name);
        if(newValue.name === "IoT" || newValue.name === "AI" || newValue.name === "AR"){
            var Fitems =  items.filter(function(item) {
                //return item.image.alt === "IoT";
                return item.image.alt.toLowerCase().indexOf(newValue.name.toLowerCase()) > -1;
            });
        }else{
            var Fitems = items;
        }
        setItemCard(Fitems)
    };

    return (
        <section className={"HomeDivContent"} style={{backgroundSize: "100% 60vh"}}>
            <div className={clsx(classes.root, "TopDiv")}>
                <Grid container alignItems={'center'} alignContent={'center'} justify={'center'} spacing={3}
                      className={classes.GridContainer}>
                    <Grid item xs={12}>
                        <Jumbotron fluid id={"PageTitle"}>
                            <Container>
                                <Typography variant={"h1"} className={"title-text"}>
                                    Blog
                                </Typography>
                            </Container>
                        </Jumbotron>
                    </Grid>
                    <Grid item xs={12}>
                    </Grid>
                    <Grid item xs={gridCenterWidth}>
                        <Card_MC className={classes.AchievementCard}>
                            <Menu pointing secondary color={"blue"}>
                                <Menu.Item
                                    name='All'
                                    active={activeItem === 'All'}
                                    onClick={handleItemClick}
                                />
                                <Menu.Item
                                    name='IoT'
                                    active={activeItem === 'IoT'}
                                    onClick={handleItemClick}
                                />
                                <Menu.Item
                                    name='AI'
                                    active={activeItem === 'AI'}
                                    onClick={handleItemClick}
                                />
                                <Menu.Item
                                    name='AR'
                                    active={activeItem === 'AR'}
                                    onClick={handleItemClick}
                                />
                            </Menu>
                            <div className={classes.AchievementPadding}>
                                <Item.Group divided className={"company-logo"} items={itemCard}/>
                            </div>
                        </Card_MC>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={"BtmSpc"}>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </section>
    );
}