import {Label} from "semantic-ui-react";
import React from "react";
import NUS from './Image/NUS.png'
import ITE from './Image/ite.png'
import RPT from './Image/RPT.png'
import SPT from './Image/SPT.png'
import TP from './Image/TP.jpg'
import SIT from './Image/SIT.png'
import KLK_OLEO from './Image/KLK_OLEO.png'
import NSCC from './Image/NSCC.png'
import DSTA from './Image/dsta.jpg'
import HP from './Image/HP.jpg'
import JCH from './Image/JCH.jpg'
import CocaCola from './Image/CocaCola.png'
import MAKINO from './Image/MAKINO.png'
import Beyonics from './Image/Beyonics.png'
import goodyear from './Image/goodyear.png'
import keppel_fels from './Image/keppel_fels.png'
import petronas from './Image/petronas.jpg'
import technip from './Image/technip.png'
import ARTC from './Image/ARTC.png'
import AAC from './Image/AAC.png'
import ASM from './Image/ASM.png'
import WD from './Image/WD.png'
import Seagate from './Image/Seagate.png'
import CollinsAerospace from './Image/Collins-Aerospace.jpeg'

export default function ProjectItems() {

    const extraMap = (content) => (
        <Label.Group tag color='teal'>
            {
                content.map((contentPart) => (
                    <Label as='a'>
                        {contentPart}
                    </Label>
                ))}
        </Label.Group>
    )

    const LabelJSON_AI = (
        {
            as: 'a',
            color: 'blue',
            content: 'IoT & A.I.',
            icon: 'info circle',
            ribbon: true,
        }
    )

    const LabelJSON_IoT = (
        {
            as: 'a',
            color: 'black',
            content: 'IoT',
            icon: 'info circle',
            ribbon: true,
        }
    )

    const LabelJSON_IoT_AR = (
        {
            as: 'a',
            color: 'green',
            content: 'IoT & AR',
            icon: 'info circle',
            ribbon: true,
        }
    )

    const LabelJSON_IoT_AR_AI = (
        {
            as: 'a',
            color: 'green',
            content: 'IoT & AR & AI',
            icon: 'info circle',
            ribbon: true,
        }
    )

    const style = (
        {
            height: '200px',
            width: 'auto',
            padding: "2em"
        }
    )
    const items = [
        {
            childKey: 0,
            image: {
                src: NUS, alt: "IoT", label: LabelJSON_IoT, style: style, className:"ProjectImageDiv"
            },
            header: 'National University of Singapore (NUS)',
            description: 'ThingWorx setup, training and lab setup',
            color: 'red',
            extra: extraMap("Academic,ThingWorx".split(',')),
        },
        {
            childKey: 1,
            image: {
                src: ITE, alt: "IoT", label: LabelJSON_IoT, style: style, className:"ProjectImageDiv"
            },
            header: 'Institute of Techical Education (ITE) College East',
            description: 'ThingWorx setup, training and lab setup',
            color: 'blue',
            extra: extraMap("Academic,ThingWorx".split(',')),
        },
        {
            childKey: 2,
            image: {
                src: RPT, alt: "IoT", label: LabelJSON_IoT, style: style, className:"ProjectImageDiv"
            },
            header: 'Republic Polytechnic',
            description: 'ThingWorx setup, training and lab setup',
            color: 'blue',
            extra: extraMap("Academic,ThingWorx".split(',')),
        },
        {
            childKey: 3,
            image: {
                src: SPT, alt: "IoT", label: LabelJSON_IoT, style: style, className:"ProjectImageDivLong"
            },
            header: 'Singapore Polytechnic',
            description: 'ThingWorx setup, training and lab setup',
            color: 'blue',
            extra: extraMap("Academic,ThingWorx".split(',')),
        },
        {
            childKey: 4,
            image: {
                src: TP, alt: "IoT", label: LabelJSON_IoT, style: style, className:"ProjectImageDivLong"
            },
            header: 'Temasek Polytechnic',
            description: 'ThingWorx setup, training and lab setup',
            color: 'blue',
            extra: extraMap("Academic,ThingWorx".split(',')),
        },
        {
            childKey: 5,
            image: {
                src: SIT, alt: "IoT", label: LabelJSON_IoT, style: style, className:"ProjectImageDiv"
            },
            header: 'Singapore Institute of Technology (SIT)',
            description: 'ThingWorx setup, training and lab setup',
            color: 'blue',
            extra: extraMap("Academic,ThingWorx".split(',')),
        },
        {
            childKey: 6,
            image: {
                src: CollinsAerospace, alt: "IoT", label: LabelJSON_IoT, style: style, className:"ProjectImageDivLong"
            },
            header: 'Collins Aerospace Pte Ltd',
            description: 'Full-scale multi factory digital transformation project for Collins factories in Bedok and Changi, with over 900 users',
            color: 'blue',
            extra: extraMap("Aerospace,Smart Manufacturing,ThingWorx".split(',')),
        },
        {
            childKey: 7,
            image: {
                src: KLK_OLEO, alt: "IoT", label: LabelJSON_IoT, style: style, className:"ProjectImageDivLong"
            },
            header: 'KLK Oleomas Sdn Bhd',
            description: 'Condition Monitoring using ThingWorx Industrial IoT Platform',
            color: 'blue',
            extra: extraMap("Chemicals,Smart Monitoring,ThingWorx".split(',')),
        },
        {
            childKey: 8,
            image: {
                src: NSCC, alt: "IoT AR AI", label: LabelJSON_IoT_AR_AI, style: style, className:"ProjectImageDivRect"
            },
            header: 'National Super Computing Center (NSCC) Singapore',
            description: 'Designed solution architecture, developed condition monitoring dashboard application on ThingWorx IoT Platform, created predictive models for detecting hard disk failures and authored augmented reality experiences for relaying information to the data centre maintenance team.',
            color: 'blue',
            extra: extraMap("Data Center,Microsoft Hololens,ThingWorx".split(',')),
        },
        {
            childKey: 9,
            image: {
                src: DSTA, alt: "IoT AR AI", label: LabelJSON_IoT_AR_AI, style: style, className:"ProjectImageDiv"
            },
            header: 'Defence Science and Technology Agency (DSTA SG)',
            description: 'Designed solution architecture, digitised the service maintenance procedure inside the workshop, developed condition monitoring dashboard application on ThingWorx IoT Platform and created augmented reality experiences to assist with on the job training and maintenance.',
            color: 'blue',
            extra: extraMap("Defense,Smart Monitoring,ThingWorx".split(',')),
        },
        {
            childKey: 10,
            image: {
                src: CocaCola, alt: "IoT", label: LabelJSON_IoT, style: style, className:"ProjectImageDivLong"
            },
            header: 'Coca Cola Pte Ltd',
            description: 'SAP HANA and ThingWorx integration for developing a smart monitoring solution',
            color: 'blue',
            extra: extraMap("F&B,Smart Manufacturing,ThingWorx".split(',')),
        },
        {
            childKey: 11,
            image: {
                src: HP, alt: "IoT", label: LabelJSON_IoT, style: style, className:"ProjectImageDiv"
            },
            header: 'Hewlett Packard Inc.',
            description: 'Developed and designed a smart connected operator application for operators working with a high precision tool on the shop floor',
            color: 'blue',
            extra: extraMap("Information Technology,Smart Monitoring,ThingWorx".split(',')),
        },
        {
            childKey: 12,
            image: {
                src: MAKINO, alt: "IoT", label: LabelJSON_IoT, style: style, className:"ProjectImageDivRect"
            },
            header: 'Makino Pte Ltd',
            description: 'Developed and designed a smart monitoring application',
            color: 'blue',
            extra: extraMap("Factory,Smart Manufacturing,ThingWorx".split(',')),
        },
        {
            childKey: 13,
            image: {
                src: Beyonics, alt: "IoT", label: LabelJSON_IoT, style: style, className:"ProjectImageDivRect"
            },
            header: 'Beyonics Precision',
            description: 'Developed and designed a smart monitoring application',
            color: 'blue',
            extra: extraMap("Factory,Smart Manufacturing,ThingWorx".split(',')),
        },
        {
            childKey: 14,
            image: {
                src: goodyear, alt: "IoT", label: LabelJSON_IoT, style: style, className:"ProjectImageDiv"
            },
            header: 'Goodyear Sdn Bhd',
            description: 'SAP HANA and ThingWorx integration for developing a smart monitoring solution',
            color: 'blue',
            extra: extraMap("Factory,Smart Manufacturing,ThingWorx".split(',')),
        },
        {
            childKey: 15,
            image: {
                src: JCH, alt: "IoT", label: LabelJSON_IoT, style: style, className:"ProjectImageDiv"
            },
            header: 'Johnson Control Hitachi Sdn Bhd',
            description: 'Developed and designed a smart monitoring application',
            color: 'blue',
            extra: extraMap("Factory,Smart Manufacturing,ThingWorx".split(',')),
        },
        {
            childKey: 16,
            image: {
                src: keppel_fels, alt: "IoT", label: LabelJSON_IoT, style: style, className:"ProjectImageDivLong"
            },
            header: 'Keppel Fels Pte Ltd',
            description: 'Developed and designed a smart monitoring application',
            color: 'blue',
            extra: extraMap("Oil & Gas,Smart Monitoring,ThingWorx".split(',')),
        },
        {
            childKey: 17,
            image: {
                src: petronas, alt: "IoT AI", label: LabelJSON_AI, style: style, className:"ProjectImageDivLong"
            },
            header: 'PETRONAS Research Sdn Bhd (PRSB)',
            description: 'Developed condition monitoring dashboard application on ThingWorx IoT Platform and created machine-learning models for predictive maintenance for oil & gas offshore pipeline.',
            color: 'blue',
            extra: extraMap("Oil & Gas,Smart Monitoring,ThingWorx".split(',')),
        },
        {
            childKey: 18,
            image: {
                src: technip, alt: "IoT", label: LabelJSON_IoT, style: style, className:"ProjectImageDiv"
            },
            header: 'Technip FMC Pte Ltd',
            description: 'Developed and designed a smart monitoring application',
            color: 'blue',
            extra: extraMap("Oil & Gas,Smart Monitoring,ThingWorx".split(',')),
        },
        {
            childKey: 19,
            image: {
                src: ARTC, alt: "IoT", label: LabelJSON_IoT, style: style, className:"ProjectImageDiv"
            },
            header: 'Advanced Remanufacturing and Technology Centre (ARTC) Singapore',
            description: 'Developed and designed a smart monitoring application',
            color: 'blue',
            extra: extraMap("Research,Smart Monitoring,ThingWorx".split(',')),
        },
        {
            childKey: 20,
            image: {
                src: AAC, alt: "IoT", label: LabelJSON_IoT, style: style, className:"ProjectImageDiv"
            },
            header: 'AAC Technologies Pte Ltd',
            description: 'Developed and designed a smart monitoring application',
            color: 'blue',
            extra: extraMap("Semicon,Smart Monitoring,ThingWorx".split(',')),
        },
        {
            childKey: 21,
            image: {
                src: ASM, alt: "IoT AR AI", label: LabelJSON_IoT_AR_AI, style: style, className:"ProjectImageDiv"
            },
            header: 'ASM Pte Ltd',
            description: 'Developed and designed a smart monitoring application, condition monitoring, developed AI for sample fault detection and augemented reality for data viewing.',
            color: 'blue',
            extra: extraMap("Semicon,Smart Monitoring,ThingWorx".split(',')),
        },
        {
            childKey: 22,
            image: {
                src: Seagate, alt: "IoT AI", label: LabelJSON_AI, style: style, className:"ProjectImageDivRect"
            },
            header: 'Seagate Technology (Thailand) Co., Ltd',
            description: 'Designed solution architecture, developed condition monitoring dashboard application on ThingWorx IoT Platform and created predictive models for improving process yield during the bonding process.',
            color: 'blue',
            extra: extraMap("Semicon,Smart Monitoring,ThingWorx".split(',')),
        },
        {
            childKey: 23,
            image: {
                src: WD, alt: "IoT AI", label: LabelJSON_AI, style: style, className:"ProjectImageDivLong"
            },
            header: 'Western Digital (Thailand) Co., Ltd.',
            description: 'Designed solution architecture, developed condition monitoring dashboard application on ThingWorx IoT Platform and created predictive models for improving process yield during the bonding process.',
            color: 'blue',
            extra: extraMap("Semicon,Smart Monitoring,ThingWorx".split(',')),
        },
    ]

    return items;
}
